import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  column-gap: 28px;
  margin-top: 16px;
  align-items: center; 
  padding-bottom: 31px; 
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    column-gap: 24px;  
    padding-bottom: 20px;
  }
`;
