import styled from "styled-components";
import {defaultBreakpoints} from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-radius: 16px;  
`;

export const ItemsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    @media (max-width: ${defaultBreakpoints.medium}) {
      grid-template-columns: 1fr;
      margin-bottom: 16px;
    }
`;

export const NumberWrapper = styled.div`
  background-color: ${({ theme }) => theme.default.color.primary};
  color: white;
  align-items: center;  
  padding: 0.5rem 1rem;
  display: flex;
  font-family: ${({ theme }) => theme.default.fontFamily.primaryFont};
  font-weight: 800;
  border-radius: 16px 0 0 16px;  
  h1 {
    margin: 0;
    font-size: 52px;
    line-height: 60px;  
  }
`;

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.default.color.primary};
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
`;

export const InfoWrapper = styled.div`
  padding: 8px;
  a {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    cursor: pointer;
    &:hover {
      text-decoration: underline;  
    }
  }
`;
