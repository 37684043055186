import { FC, useContext, useEffect, useState } from "react";
import Title from "Components/Title";
import Item from "./Item";
import { Wrapper } from "./MostRead.styled";
import axios from "axios";
import { PublicationContext } from "common/context/Publication";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import MPU from "../../Adverts/MPU";
import { ItemsWrapper } from "./Item/Item.styled";

type Props = {
  data: any;
  mpu_id: number;
};

const MostRead: FC<Props> = ({ mpu_id }) => {
  const [data, setData] = useState<any>();
  const { publication } = useContext<publicationTypes>(PublicationContext);

  const FetchData = async () => {
    try {
      const fetchedData = await axios.get(
        `/api/most_popular?publication=${publication}&number=${6}`
      );

      if (fetchedData?.status === 404 || !Array.isArray(fetchedData.data)) {
        setData(null);
      } else {
        setData(fetchedData.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setData(null);
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <Title type="section">Most Read</Title>
      <Wrapper>
        <ItemsWrapper>
        {data &&
          data?.map((item: any, i: number) => {
            return <Item key={i} article={item} number={i + 1} />;
          })}
        </ItemsWrapper>
        <MPU className="most_read_item" num={mpu_id} />
      </Wrapper>
    </>
  );
};

export default MostRead;
