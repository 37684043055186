import { FC } from "react";
import {
  NumberWrapper,
  InfoWrapper,
  TitleWrapper,
  Wrapper,
} from "./Item.styled";

type Props = {
  number: number;
  article: any;
};

const Item: FC<Props> = ({ number, article }) => {
  if (!article) {
    return null;
  }
  return (
    <Wrapper>
      <NumberWrapper>
        <h1>{number}</h1>
      </NumberWrapper>
      <InfoWrapper>
        <TitleWrapper>
          {article.articleTypes &&
            article.articleTypes[0] &&
            article.articleTypes[0].name &&
            article.articleTypes[0].name}
        </TitleWrapper>
        <a style={{ textAlign: "left" }} href={article.path && article.path}>
          {article.titleShort && article.titleShort}
        </a>
      </InfoWrapper>
    </Wrapper>
  );
};

export default Item;
